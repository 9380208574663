import React from 'react';
import RequireAuth from '../common/RequireAuth';

const NotFound = (props) => {
  return <h1>404 NotFound</h1>;
};

const NotFoundWrapper = () => {
  return (
    <RequireAuth>
      <NotFound />
    </RequireAuth>
  );
};

export default NotFoundWrapper;
