import React from 'react';
import { InstantSearch } from '../../common/algoliasearch';
import TreeForm from '../../components/TreeForm';

const TreeFormWrapper = () => {
  return (
    <InstantSearch indexName='tree_index'>
      <TreeForm />
    </InstantSearch>
  );
};

export default TreeFormWrapper;
