import React from 'react';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import LandModal from '../components/LandModal';

const TreeDetail = (props) => {
  const { id } = useParams();
  return (
    <>
      {/* <Button type='link' onClick={() => window.location.assign('/trees')}> */}
      <Button type='link' onClick={() => window.history.back()}>
        {`< `}Back to list
      </Button>
      <LandModal
        land={{
          농가: '상림원',
          필지수종: ['그라스류', '구근류'],
          필지면적: 124124,
          보유수목수량: 12323,
          필지판매가: 231312421423,
          최근방문일: '2022-12-03T16:41:48.546Z',
          필지주소: '전라북도 고창군 성내면 덕산리 562-6',
          objectID: '587173001',
        }}
      />
    </>
  );
};

export default TreeDetail;
