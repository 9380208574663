import React, { useEffect, useRef, useState } from 'react';
import {
  UserOutlined,
  ImportOutlined,
  MenuOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Avatar, Dropdown } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFuro } from 'furo-react';

const { Header, Content, Sider } = Layout;

const items2 = [
  {
    key: 'sub1',
    icon: <UserOutlined />,
    label: 'subnav 1',
    children: [
      {
        key: 'subkey 1',
        label: 'option1',
      },
    ],
  },
];

const App = ({ children }) => {
  const { logout, user } = useFuro();
  const [menuIndex, setMenuIndex] = useState(['1']);
  const location = useLocation();
  const menuRef = useRef();
  const [menuWidth, setMenuWidth] = useState(200);
  const [logoVisible, setLogoVisible] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      setMenuWidth(menuRef?.current?.menu?.list?.clientWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuRef]);
  useEffect(() => {
    if (logoVisible && menuWidth < 54) setLogoVisible(false);
    else if (!logoVisible && menuWidth > 260) setLogoVisible(true);
  }, [menuWidth]);
  useEffect(() => {
    setMenuIndex(getHeaderMenuIndex());
  }, [location]);
  const items = [
    {
      label: user ? user.email : 'rootrix@rootrix.com',
      key: 'item-1',
      icon: <UserOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: '로그아웃',
      key: 'item-2',
      icon: <ImportOutlined />,
      onClick: logout,
    },
  ];

  const menus = [
    {
      key: '1',
      label: '수목 목록',
      url: '/trees',
    },
    {
      key: '2',
      label: '필지 목록',
      url: '/lands',
    },
    {
      key: '3',
      label: '농가 목록',
      url: '/nurseries',
    },
    {
      key: '4',
      label: '수목 등록',
      url: '/register/trees',
    },
    {
      key: '5',
      label: '필지 등록',
      url: '/register/land',
    },
    {
      key: '6',
      label: '농가 등록',
      url: '/register/nursery',
    },
    // {
    //   key: '7',
    //   icon: (
    //     <Avatar
    //       style={{ right: 0, marginTop: 12, marginBottom: -12 }}
    //       size={40}
    //       icon={<UserOutlined />}
    //       src={user?.profile_url}
    //     />
    //   ),
    //   title: 'My Page',
    //   children: items,
    // },
  ];

  const getHeaderMenuIndex = () => {
    const found = menus.find((e) => e.url === window.location.pathname);
    return found ? [found.key] : null;
  };

  const navigate = useNavigate();
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        className='header'
        style={{ background: '#111', paddingInline: 24 }}
      >
        {/* logo */}
        {logoVisible && (
          <img
            style={{
              height: '100%',
              objectFit: 'contain',
              marginLeft: -14,
              marginRight: 14,
              padding: 16,
              float: 'left',
              cursor: 'pointer',
            }}
            alt='rootrix-logo'
            src='/rootrix_logo.png'
            onClick={() => (window.location.href = window.location.origin)}
          />
        )}
        <Menu
          ref={menuRef}
          theme='dark'
          style={{
            background: 'inherit',
            marginRight: 50,
          }}
          mode='horizontal'
          defaultSelectedKeys={['1']}
          selectedKeys={menuIndex}
          items={menus}
          onClick={({ key }) =>
            navigate(menus.find((item) => item.key === key).url)
          }
          overflowedIndicator={
            menuWidth < 138 ? <MenuOutlined /> : <EllipsisOutlined />
          }
        />
        <div
          style={{
            float: 'right',
            marginTop: -64,
          }}
        >
          <Dropdown menu={{ items }} placement='bottomRight'>
            <Avatar size={40} icon={<UserOutlined />} src={user?.profile_url} />
          </Dropdown>
        </div>
      </Header>
      <Layout>
        <Layout
          style={{
            // padding: '0 24px 24px',
            background: 'white',
          }}
        >
          <Content
            className='site-layout-background'
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default App;
