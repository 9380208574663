import React, { useEffect, useState } from 'react';
import { Form, Select, Input, notification, Typography } from 'antd';
import InputNumber from './InputNumber';
import Button from './Button';
import DatePicker from './DatePicker';
import UploadImages from './UploadImages';
import { isMobile } from 'react-device-detect';
import { registerNursery } from '../api';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { getNurseryList, getNurseryMainList } from '../common/algoliasearch';
const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    span: isMobile ? 24 : 6,
  },
  wrapperCol: {
    span: isMobile ? 24 : 18,
  },
};
const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const App = () => {
  const navigate = useNavigate();
  const initialValues = useLoaderData();
  const [nurseryList, setNurseryList] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [form] = Form.useForm();
  const [nurseryMainList, setNurseryMainList] = useState([]);

  useEffect(() => {
    (async () => {
      const nurseryList = await getNurseryList();
      setNurseryList(nurseryList);
      const nurseryMainList = await getNurseryMainList();
      setNurseryMainList(nurseryMainList);
    })();
  }, []);

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    if (nameError) {
      onFinishFailed({ errorFields: [{ name: '농가명' }] });
      return;
    }
    await registerNursery({ ...initialValues, ...values });
    notification.success({
      message: '등록 성공',
    });
    navigate('/nurseries');
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: '오류가 발생했습니다',
      description: `아래 항목을 채워주세요: ${errorInfo.errorFields.map(
        (error) => error.name
      )}`,
    });
  };

  const onInputPhoneNumber = ({ target }) => {
    target.value = target.value
      .replace(/[^0-9]/g, '')
      .replace(
        /(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g,
        '$1-$2-$3'
      );
  };

  const checkNameValidation = async (e) => {
    const existName = nurseryList.find(
      (nursery) => nursery?.name?.trim() === e?.target?.value?.trim()
    );
    if (existName) {
      setNameError(true);
      return false;
    }
    setNameError(false);
    return true;
  };

  const checkPhoneValidation = async (e) => {
    const existPhone = nurseryList.find(
      (nursery) => nursery?.phone?.trim() === e?.target?.value?.trim()
    );
    if (existPhone) {
      setPhoneError(true);
      return false;
    }
    setPhoneError(false);
    return true;
  };

  return (
    <>
      <Form
        name='nursery'
        form={form}
        {...formItemLayout}
        layout={isMobile ? 'vertical' : 'horizontal'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          ...initialValues,
          //   'input-number': 3,
          //   'checkbox-group': ['A', 'B'],
          //   rate: 3.5,
          최근방문일: null,
        }}
        size='large'
        style={{ maxWidth: 480, margin: 'auto' }}
      >
        {/* 농가명 */}
        <Form.Item
          name='농가명'
          label='농가명'
          rules={[
            {
              required: true,
              message: '농가명을 입력해주세요.',
            },
          ]}
          validateStatus={nameError ? 'error' : 'success'}
          help={nameError ? '이미 존재하는 이름입니다.' : null}
        >
          <Input
            // status={nameError ? 'error' : null}
            onChange={checkNameValidation}
          />
        </Form.Item>
        {/* 농가주소 */}
        <Form.Item name='농가주소' label='농가주소'>
          <Input />
        </Form.Item>
        {/* 농가주 */}
        <Form.Item name='농가주' label='농가주'>
          <Input />
        </Form.Item>
        {/* 연락처 */}
        <Form.Item
          name='연락처'
          label='연락처'
          rules={[
            {
              required: true,
              message: '연락처를 입력해주세요.',
            },
          ]}
          validateStatus={phoneError ? 'error' : 'success'}
          help={phoneError ? '이미 존재하는 연락처입니다.' : null}
        >
          <Input
            onInput={onInputPhoneNumber}
            maxLength='13'
            placeholder='010-1234-5678'
            onChange={checkPhoneValidation}
          />
        </Form.Item>
        {/* 농가 면적 */}
        <Form.Item name='농가면적' label='농가 면적 (평)'>
          <InputNumber placeholder='120000' addonBefore='평' />
        </Form.Item>
        {/* 보유 수목 수량 */}
        <Form.Item name='보유수목수량' label='보유 수목 수량 (주)'>
          <InputNumber placeholder='7000' addonBefore='주' />
        </Form.Item>
        {/* 농가 가치 */}
        <Form.Item name='농가가치' label='농가 가치 (원)'>
          <InputNumber placeholder='10000000' addonBefore='₩' />
        </Form.Item>
        {/* 주력 분야 */}
        <Form.Item
          name='주력분야'
          label='주력 분야'
          hasFeedback
          rules={[
            {
              // required: true,
              message: '주력 분야를 선택해주세요!',
            },
          ]}
        >
          <Select placeholder='주력 분야를 선택해주세요'>
            <Option value='초화'>초화</Option>
            <Option value='관목'>관목</Option>
            <Option value='교목'>교목</Option>
            <Option value='초화 / 관목'>초화 / 관목</Option>
            <Option value='초화 / 교목'>초화 / 교목</Option>
            <Option value='관목 / 교목'>관목 / 교목</Option>
            <Option value='초화 / 관목 / 교목'>초화 / 관목 / 교목</Option>
          </Select>
        </Form.Item>
        {/* 주요수종 */}
        <Form.Item
          name='주요수종'
          label='주요 수종'
          rules={[
            {
              // required: true,
              message: '주요 수종을 선택해주세요!',
              type: 'array',
            },
          ]}
        >
          <Select mode='tags' placeholder='주력 분야를 선택해주세요'>
            {nurseryMainList.map((nurseryMain, index) => (
              <Option key={index} value={nurseryMain.value}>
                {nurseryMain.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* 기타 메모 */}
        <Form.Item name='기타메모' label='기타 메모'>
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
        {/* 최근 방문일 */}
        <Form.Item name='최근방문일' label='최근 방문일'>
          <DatePicker placement='topRight' />
        </Form.Item>
        {/* 촬영물 */}
        <Form.Item
          name='촬영물'
          label='촬영물'
          valuePropName='fileList'
          getValueFromEvent={normFile}
        >
          <UploadImages form={form} />
        </Form.Item>
        <Form.Item name='메타데이터' hidden />
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
        >
          <Button type='primary' htmlType='submit' fill={'yes'} size='xl'>
            저장하기
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default App;
