import React, { useState } from 'react';
import { Button, Descriptions, notification, Popconfirm } from 'antd';
import { deleteLand } from '../api';
import { useInstantSearch } from 'react-instantsearch-hooks-web';

// const contentStyle = {
//   margin: 0,
//   height: '360px',
//   color: '#fff',
//   lineHeight: '360px',
//   textAlign: 'center',
//   background: '#364d79',
// };

const LandModal = (props) => {
  const { data } = props;
  const { refresh } = useInstantSearch();
  // const onChange = (currentSlide) => {
  //   console.log(currentSlide);
  // };

  // const [index, setIndex] = useState(2);

  // const onClick = (value) => {
  //   setIndex(value);
  // };
  const onDeleteConfirm = async () => {
    if (!data.objectID) notification.error({ message: 'Something wrong' });
    const response = await deleteLand(data.objectID);
    if (!response) {
      notification.error({ message: 'Something wrong' });
    }
    notification.success({ message: '삭제중입니다.', duration: 2 });
    setTimeout(() => {
      refresh();
    }, 2000);
  };
  return (
    // <>
    //   <Descriptions
    //     title=''
    //     bordered
    //     column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
    //   >
    //     {/* {columns.splice(-1).map((i) => {
    //       return (
    //         <Descriptions.Item key={i.dataIndex} label={i.title}>
    //           {i?.render ? i.render(land[i.dataIndex]) : land[i.dataIndex]}
    //         </Descriptions.Item>
    //       );
    //     })} */}
    //   </Descriptions>
    // </>
    <>
      <Descriptions
        title=''
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <>
          {Object.keys(data).map((key) => {
            if (key === '_highlightResult') return null;
            if (key === '__position') return null;
            let result = data[key];
            if (key === '최근방문일')
              result = new Date(result)?.toLocaleString().split(',')[0];
            return (
              <Descriptions.Item key={key} label={key}>
                <>{data && result ? `${result}` : ``}</>
              </Descriptions.Item>
            );
          })}
        </>
      </Descriptions>
      <div
        style={{ display: 'flex', marginTop: 20, justifyContent: 'flex-end' }}
      >
        <Button style={{ minWidth: 80 }} href={`/lands/${data.objectID}/edit`}>
          Edit
        </Button>
        <div style={{ width: 20 }} />
        <Popconfirm
          title='삭제하시겠습니까?'
          okText='삭제'
          cancelText='취소'
          okButtonProps={{ danger: true }}
          onConfirm={onDeleteConfirm}
        >
          <Button danger style={{ minWidth: 80 }}>
            Delete
          </Button>
        </Popconfirm>
      </div>
    </>
  );
};

export default LandModal;
