import React, { useState } from 'react';
import { Input } from 'antd';
// import { getAddress } from '../api';

const App = (props) => {
  const { callback, form } = props;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(props?.value);

  const positionError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('User denied the request for Geolocation.');
        break;

      case error.POSITION_UNAVAILABLE:
        alert('Location information is unavailable.');
        break;

      case error.TIMEOUT:
        alert('The request to get user location timed out.');
        break;

      case error.UNKNOWN_ERROR:
        alert('An unknown error occurred.');
        break;
      default:
        break;
    }
  };
  const locateHere = async (callback) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 10000);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async ({ coords }) => {
          // const { latitude, longitude } = coords;
          // console.log(coords);
          // const addr = await getAddress({ lat: latitude, lng: longitude });
          // console.log(addr);
          // const result = addr.formatted_address.split('대한민국 ')[1];
          const value = await callback(coords);
          setValue(value);
          setLoading(false);
        },
        positionError,
        {
          enableHighAccuracy: true,
          timeout: 10000,
        }
      );
    } else {
      alert('Geolocation is not supported');
      return setLoading(false);
    }
  };
  return (
    <Input.Search
      loading={loading}
      disabled={loading}
      enterButton='현재 주소'
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        form.setFieldValue('필지주소', e.target.value);
      }}
      onSearch={() => locateHere(callback)}
    />
  );
};

export default App;
