import React, { useEffect, useState } from 'react';
import { Table, Tag } from 'antd';
import Button from '../components/Button';
import { InstantSearch } from '../common/algoliasearch';
import NurseryModal from '../components/NurseryModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { useHits, useInstantSearch, Configure } from 'react-instantsearch-hooks-web';
import { SyncOutlined } from '@ant-design/icons';

const columns = [
  {
    title: '농가명',
    dataIndex: '농가명',
    // filters: [],
    sorter: (a, b) => {
      if (a.농가명 < b.농가명) return -1;
      if (a.농가명 > b.농가명) return 1;
      return 0;
    },
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    // onFilter: (value, record) => record.name.indexOf(value) === 0,
  },
  {
    title: '연락처',
    dataIndex: '연락처',
    // defaultSortOrder: 'descend',
    // sorter: (a, b) => a.age - b.age,
  },
  {
    title: '주력 분야',
    dataIndex: '주력분야',
    // defaultSortOrder: 'descend',
    // sorter: (a, b) => a.age - b.age,
  },
  {
    title: '주요 수종',
    dataIndex: '주요수종',
    filters: [],
    onFilter: (value, record) => record.address.indexOf(value) === 0,
    render: (items) => (
      <>{items && items.map((item, index) => <Tag key={index}>{item}</Tag>)}</>
    ),
  },
];
const onChange = (pagination, filters, sorter, extra) => {
  console.log('params', pagination, filters, sorter, extra);
};

const NurseryList = () => {
  const navigate = useNavigate();
  const { hits } = useHits();
  const location = useLocation();
  const { refresh } = useInstantSearch();
  const [fetchLoading, setFetchLoading] = useState(false);
  useEffect(() => {
    console.log(hits.length);
  }, [hits]);
  useEffect(() => {
    setTimeout(() => {
      refresh();
    }, 2000);
  }, [location]);
  useEffect(() => {
    if (!fetchLoading) return;
    setTimeout(() => {
      setFetchLoading(false);
    }, 500);
  }, [fetchLoading]);
  const onRefreshClick = () => {
    refresh();
    setFetchLoading(true);
  };
  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            float: 'left',
            marginBottom: 12,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Button
            icon={
              <div>
                <SyncOutlined />
              </div>
            }
            onClick={onRefreshClick}
            loading={fetchLoading}
          />
          <div style={{ marginLeft: 20, fontSize: 16 }}>
            {hits && hits.length > 0 && `총 등록건수: ${hits.length} 건`}
          </div>
        </div>
        <div style={{ float: 'right', marginBottom: 12 }}>
          <Button type='primary' onClick={() => navigate('/register/nursery')}>
            + 농가 등록
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={hits}
        rowKey={'objectID'}
        onChange={onChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              // console.log(record.objectID);
              // window.location.href = `/lands/${record.objectID}`;
              // navigate(`/lands/${record.objectID}/edit`);
            },
          };
        }}
        expandable={{
          expandedRowRender: (record) => <NurseryModal data={record} />,
          expandRowByClick: true,
        }}
      />
    </>
  );
};

const NurseryListWrapper = () => {
  return (
    <InstantSearch indexName='nursery_index'>
      <Configure hitsPerPage={1000} />
      <NurseryList />
    </InstantSearch>
  );
};

export default NurseryListWrapper;
