import { Button } from 'antd';

const App = ({ ...props }) => {
  return (
    <Button
      style={{
        width: props.fill && '100%',
        minWidth: props.size === 'xl' ? 160 : 120,
        height: props.size === 'xl' ? 54 : 40,
        fontWeight: '600',
      }}
      {...props}
      htmlType='submit'
    />
  );
};

export default App;
