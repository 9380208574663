import axios from 'axios';

axios.defaults.baseURL = `${process.env.REACT_APP_API}/api/`;

export const registerLand = async (values) => {
  const { data } = await axios.post(
    '/lands',
    {
      ...values,
    },
    {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
    }
  );
  console.log(data);
  return data;
};

export const registerNursery = async (values) => {
  const { data } = await axios.post(
    '/nurseries',
    {
      ...values,
    },
    {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
    }
  );
  console.log(data);
  return data;
};

export const registerTree = async (values) => {
  const { data } = await axios.post(
    '/trees',
    {
      ...values,
    },
    {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
    }
  );
  console.log(data);
  return data;
};

export const getAddress = async ({ lat, lng }) => {
  const { data } = await axios.get(
    `/geocode/json?latlng=${lat},${lng}&language=ko&result_type=street_address`,
    {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
    }
  );
  console.log(data.results);
  return data.results[0];
};

// export const treeListLoader = async () => {
//   const { data } = await axios.get(`/trees`, {
//     headers: {
//       Authorization: process.env.REACT_APP_API_KEY,
//     },
//   });
//   return data;
// };

export const treeLoader = async ({ params }) => {
  const { id } = params;
  const { data } = await axios.get(`/trees/${id}`, {
    headers: {
      Authorization: process.env.REACT_APP_API_KEY,
    },
  });
  return data;
};

// export const landListLoader = async () => {
//   const { data } = await axios.get(`/lands`, {
//     headers: {
//       Authorization: process.env.REACT_APP_API_KEY,
//     },
//   });
//   return data;
// };

export const landLoader = async ({ params }) => {
  const { id } = params;
  const { data } = await axios.get(`/lands/${id}`, {
    headers: {
      Authorization: process.env.REACT_APP_API_KEY,
    },
  });
  return data;
};

export const nurseryLoader = async ({ params }) => {
  const { id } = params;
  const { data } = await axios.get(`/nurseries/${id}`, {
    headers: {
      Authorization: process.env.REACT_APP_API_KEY,
    },
  });
  return data;
};

export const updateTree = async (id, values) => {
  const { data } = await axios.put(
    `/trees/${id}`,
    {
      ...values,
    },
    {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
    }
  );
  console.log(data);
  return data;
};

export const updateLand = async (id, values) => {
  const { data } = await axios.put(
    `/lands/${id}`,
    {
      ...values,
    },
    {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
    }
  );
  console.log(data);
  return data;
};

export const updateNursery = async (id, values) => {
  const { data } = await axios.put(
    `/nurseries/${id}`,
    {
      ...values,
    },
    {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
    }
  );
  console.log(data);
  return data;
};

export const deleteTree = async (id) => {
  const { data } = await axios.delete(`/trees/${id}`, {
    headers: {
      Authorization: process.env.REACT_APP_API_KEY,
    },
  });
  console.log(data);
  return data;
};

export const deleteLand = async (id) => {
  const { data } = await axios.delete(`/lands/${id}`, {
    headers: {
      Authorization: process.env.REACT_APP_API_KEY,
    },
  });
  console.log(data);
  return data;
};

export const deleteNursery = async (id) => {
  const { data } = await axios.delete(`/nurseries/${id}`, {
    headers: {
      Authorization: process.env.REACT_APP_API_KEY,
    },
  });
  console.log(data);
  return data;
};
