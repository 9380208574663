import React, { useEffect } from 'react';
import Layout from '../components/Layout';

import { useFuro } from 'furo-react';

function RequireAuth({ component }) {
  const { isLoading, isAuthenticated, loginWithRedirect } = useFuro();

  useEffect(() => {
    setTimeout(() => {
      if (!isLoading && !isAuthenticated) {
        loginWithRedirect();
      }
    }, 500);
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return (
      <Layout>
        <>Loading...</>
      </Layout>
    );
  }
  return isAuthenticated ? <Layout>{component}</Layout> : null;
}

export default RequireAuth;
