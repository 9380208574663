const trees = [
  {
    id: 1,
    key: 1,
    name: 'Thuja Green Giant',
    area: '충남 보령',
    height: '8m',
    grade: 'R50',
    thumbnail:
      'https://cdn.shopify.com/s/files/1/0059/8835/2052/products/Thuja_Green_Giant_Tree_FGT_600x600_3bbb9f56-7eb1-481b-bc0e-5b78c0248445.jpg?v=1668624191',
    images: [
      'https://cdn.shopify.com/s/files/1/0059/8835/2052/products/Thuja_Green_Giant_Tree_FGT_600x600_3bbb9f56-7eb1-481b-bc0e-5b78c0248445.jpg?v=1668624191',
      'https://cdn.shopify.com/s/files/1/0059/8835/2052/products/Thuja_Green_Giant_Tree_FGT_600x600_3bbb9f56-7eb1-481b-bc0e-5b78c0248445.jpg?v=1668624191',
    ],
  },
  {
    id: 2,
    key: 2,
    name: 'Fragrant Tea Olive Tree',
    area: '충북 진천',
    height: '5m',
    grade: 'R40',
    thumbnail:
      'https://cdn.shopify.com/s/files/1/0059/8835/2052/products/Fragrant_Tea_Olive_FGT_600x600_1420174e-dc7a-4f46-bd2a-376d2bd9fd0b.jpg?v=1661954387',
    images: [
      'https://cdn.shopify.com/s/files/1/0059/8835/2052/products/Thuja_Green_Giant_Tree_FGT_600x600_3bbb9f56-7eb1-481b-bc0e-5b78c0248445.jpg?v=1668624191',
      'https://cdn.shopify.com/s/files/1/0059/8835/2052/products/Thuja_Green_Giant_Tree_FGT_600x600_3bbb9f56-7eb1-481b-bc0e-5b78c0248445.jpg?v=1668624191',
    ],
  },
  {
    id: 3,
    key: 3,
    name: 'Eastern White Pine',
    area: '충남 보령',
    height: '8m',
    grade: 'R25',
    thumbnail:
      'https://cdn.shopify.com/s/files/1/0059/8835/2052/products/Eastern_White_pine_FGT_600x600_813b804c-b042-4345-af5d-25f49478ae92.jpg?v=1642174544',
    images: [
      'https://cdn.shopify.com/s/files/1/0059/8835/2052/products/Thuja_Green_Giant_Tree_FGT_600x600_3bbb9f56-7eb1-481b-bc0e-5b78c0248445.jpg?v=1668624191',
      'https://cdn.shopify.com/s/files/1/0059/8835/2052/products/Thuja_Green_Giant_Tree_FGT_600x600_3bbb9f56-7eb1-481b-bc0e-5b78c0248445.jpg?v=1668624191',
    ],
  },
];

export default trees;
