import React from 'react';
import { InstantSearch } from '../../common/algoliasearch';
import NurseryForm from '../../components/NurseryForm';

const NurseryFormWrapper = () => {
  return (
    <InstantSearch indexName='nursery_index'>
      <NurseryForm />
    </InstantSearch>
  );
};

export default NurseryFormWrapper;
