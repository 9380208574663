export const parseMetadata = (exif) => {
  const DateTime = hasDateTime(exif);
  const gpsData = hasGPSdata(exif);
  const formattedTime = formatDateTime(DateTime);
  const result = {};
  if (DateTime) result.dateTime = { DateTime, display: formattedTime };
  if (gpsData)
    result.gps = { latitude: gpsData.latitude, longitude: gpsData.longitude };
  /**
   * dateTime: {
   *   DateTime: "2022:12:13 16:52:10",
   *   display: "2022년 12월 13일 16시 52분"
   * },
   * gps: {
   *   latitude: "37/1, 31/1, 4990/100"
   *   longitude: "126/1, 58/1, 1657/100"
   * }
   */
  return result;
};

export const hasDateTime = (exif) => {
  if (!exif.DateTime) return undefined;
  return exif.DateTime;
};

export const hasGPSdata = (exif) => {
  if (!exif.GPSLatitude || !exif.GPSLongitude) return undefined;
  //Degrees + Minutes/60 + Seconds/3600
  const { GPSLatitude, GPSLongitude} = exif;
  const latitude = parseExifGPSposition(GPSLatitude);
  const longitude = parseExifGPSposition(GPSLongitude);
  return { latitude, longitude };
};

const formatDateTime = (DateTime) => {
  //DateTime : "2022:12:11 19:58:56"
  if (!DateTime) return null;
  try {
    const preFormat = DateTime.replace(' ', ':');
    const [year, month, day, hour, minute, second] = preFormat.split(':');
    return `${year}년 ${month}월 ${day}일 ${hour}시 ${minute}분`;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const parseExifGPSposition = (coord) => {
  //37/1, 31/1, 4990/100
  const [degrees, minutes, seconds] = coord.trim().split(',');
  const [degreesNumerator, degreesDenominator] = degrees.split('/');
  const [minutesNumerator, minutesDenominator] = minutes.split('/');
  const [secondsNumerator, secondsDenominator] = seconds.split('/');
  const degree = degreesNumerator/degreesDenominator
  const minute = minutesNumerator/minutesDenominator
  const second = secondsNumerator/secondsDenominator
  const result = degree + minute/60 + second/3600;
  return result.toFixed(6);
}