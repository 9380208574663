import { InputNumber } from 'antd';

const App = ({ ...props }) => {
  return (
    <InputNumber
      style={{ width: '100%' }}
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
      controls={false}
      // type='number'
      {...props}
    />
  );
};

export default App;
