import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import { FuroProvider } from 'furo-react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          // colorPrimaryBg: '#11805f',
          // colorPrimary: '#11805f',
        },
      }}
    >
      <FuroProvider
        domain='https://auth.furo.one'
        clientId={
          process.env.NODE_ENV === 'production'
            ? '4802dee715e560c0c510a0c34124e861'
            : 'c67a8693fcf3cbc8910e86d5e1a2cd7f'
        }
        redirectUri={window.location.origin}
      >
        <App />
      </FuroProvider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// PWA
serviceWorkerRegistration.unregister();
