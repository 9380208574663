import React, { useEffect, useState } from 'react';
import { Table, Tag } from 'antd';
import Button from '../components/Button';
import LandModal from '../components/LandModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { InstantSearch } from '../common/algoliasearch';
import { useHits, useInstantSearch, Configure } from 'react-instantsearch-hooks-web';
import { SyncOutlined } from '@ant-design/icons';

export const columns = [
  {
    title: '농가명',
    dataIndex: '농가명',
    sorter: (a, b) => {
      if (a.농가명 < b.농가명) return -1;
      if (a.농가명 > b.농가명) return 1;
      return 0;
    },
  },
  {
    title: '필지명',
    dataIndex: '필지명',
  },
  {
    title: '필지 주소',
    dataIndex: '필지주소',
    // filters: data
    //   .filter((item) => item.필지주소)
    //   .map((item) => {
    //     return { text: item.필지주소, value: item.필지주소 };
    //   }),
    // onFilter: (value, record) => record?.필지주소?.indexOf(value) === 0,
  },
  {
    title: '필지 수종',
    dataIndex: '필지수종',
    render: (items) => (
      <>{items && items.map((item, index) => <Tag key={index}>{item}</Tag>)}</>
    ),
    // filters: data.map((item) => {
    //   return item.필지수종
    //     .map((i) => {
    //       return { text: i, value: i };
    //     })
    //     .reduce((acc, value) => ({ ...acc, ...value }), {});
    // }),
    // responsive: ['md'],
  },
  {
    title: '필지 면적 (평)',
    dataIndex: '필지면적',
    render: (value) => value && value.toLocaleString() + ' 평',
    sorter: (a, b) => {
      return a.필지면적 - b.필지면적;
    },
    responsive: ['lg'],
  },
  {
    title: '필지 판매가 (원)',
    dataIndex: '필지판매가',
    render: (value) => value && value.toLocaleString() + ' 원',
    sorter: (a, b) => {
      return a.필지판매가 - b.필지판매가;
    },
    responsive: ['lg'],
  },
  {
    title: '최근 방문일',
    dataIndex: '최근방문일',
    render: (value) => value && new Date(value)?.toLocaleString().split(',')[0],
    sorter: (a, b) => {
      return new Date(a.최근방문일) - new Date(b.최근방문일);
    },
    responsive: ['lg'],
  },
];
const onChange = (pagination, filters, sorter, extra) => {
  console.log('params', pagination, filters, sorter, extra);
};
const App = () => {
  const navigate = useNavigate();
  const { hits } = useHits();
  const location = useLocation();
  const { refresh } = useInstantSearch();
  const [fetchLoading, setFetchLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      refresh();
    }, 1000);
  }, [location]);
  useEffect(() => {
    if (!fetchLoading) return;
    setTimeout(() => {
      setFetchLoading(false);
    }, 500);
  }, [fetchLoading]);
  const onRefreshClick = () => {
    refresh();
    setFetchLoading(true);
  };
  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            float: 'left',
            marginBottom: 12,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Button
            icon={
              <div>
                <SyncOutlined />
              </div>
            }
            onClick={onRefreshClick}
            loading={fetchLoading}
          />
          <div style={{ marginLeft: 20, fontSize: 16 }}>
            {hits && hits.length > 0 && `총 등록건수: ${hits.length} 건`}
          </div>
        </div>
        <div style={{ float: 'right', marginBottom: 12 }}>
          <Button type='primary' onClick={() => navigate('/register/land')}>
            + 필지 등록
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={hits}
        rowKey={'objectID'}
        onChange={onChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              // console.log(record.objectID);
              // window.location.href = `/lands/${record.objectID}`;
              // navigate(`/lands/${record.objectID}/edit`);
            },
          };
        }}
        expandable={{
          expandedRowRender: (record) => <LandModal data={record} />,
          expandRowByClick: true,
        }}
      />
    </>
  );
};

const LandListWrapper = () => {
  return (
    <InstantSearch indexName='land_index'>
      <Configure hitsPerPage={1000} />
      <App />
    </InstantSearch>
  );
};
export default LandListWrapper;
