import React, { useEffect, useState } from 'react';
import { PlusOutlined, InboxOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
const API_BASE_URL =
  process.env.REACT_APP_API || 'https://dev.rootrix.hopae.xyz';
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const App = (props) => {
  const { form } = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [metadataList, setMetadataList] = useState([]);
  useEffect(() => {
    const files = form.getFieldsValue()['촬영물']
      ? form
          .getFieldsValue()
          ['촬영물'].map((url) => ({ name: 'image.png', status: 'done', url }))
      : [];
    setFileList(files);
    const metadata = form.getFieldsValue()['메타데이터']
      ? form.getFieldsValue()['메타데이터']
      : [];
    setMetadataList(metadata);
  }, []);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    console.log(file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    const urlList = newFileList.map((file) =>
      file?.response ? file?.response?.url : file?.url
    );
    const metadataList = newFileList.map((file) => {
      const url = file?.response ? file?.response?.url : file?.url;
      const exif = file?.response ? file?.response?.exif : {};
      return { url, exif };
    });
    setFileList(newFileList);
    setMetadataList(metadataList);
    form.setFieldsValue({ 촬영물: urlList, 메타데이터: metadataList });
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <>
      <Upload.Dragger
        action={`${API_BASE_URL}/api/upload/image`}
        name='image'
        multiple={true}
        listType='picture-card'
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        headers={{ Authorization: `${process.env.REACT_APP_API_KEY}` }}
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>사진 업로드</p>
        <p className='ant-upload-hint'>
          클릭 또는 드래그하여 여러장의 사진을
          <br />
          업로드 할 수 있습니다.
        </p>
        {/* {fileList.length >= 8 ? null : uploadButton} */}
      </Upload.Dragger>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt='example'
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
export default App;
