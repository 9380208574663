import './App.css';
import TreeList from './pages/TreeList';
import TreeDetail from './pages/TreeDetail';
import TreeForm from './pages/Register/TreeForm';
import LandList from './pages/LandList';
import LandDetail from './pages/LandDetail';
import LandForm from './pages/Register/LandForm';
import NurseryList from './pages/NurseryList';
import NurseryForm from './pages/Register/NurseryForm';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import NotFound from './pages/NotFound';
import RequireAuth from './common/RequireAuth';
// import { loader as treeLoader } from './routes/tree';
import { treeLoader, landLoader, nurseryLoader } from './api';

const protectedRoutes = [
  {
    path: '/',
    element: <TreeList />,
  },
  {
    path: '/trees',
    element: <TreeList />,
  },
  {
    path: '/trees/:id',
    element: <TreeDetail />,
    loader: treeLoader,
  },
  {
    path: '/register/trees',
    element: <TreeForm />,
  },
  {
    path: '/trees/:id/edit',
    element: <TreeForm />,
    loader: treeLoader,
  },
  {
    path: '/lands',
    element: <LandList />,
  },
  {
    path: '/lands/:id',
    element: <LandDetail />,
    loader: landLoader,
  },
  {
    path: '/register/land',
    element: <LandForm />,
  },
  {
    path: '/lands/:id/edit',
    element: <LandForm />,
    loader: landLoader,
  },
  {
    path: '/nurseries',
    element: <NurseryList />,
  },
  {
    path: '/register/nursery',
    element: <NurseryForm />,
  },
  {
    path: '/nurseries/:id/edit',
    element: <NurseryForm />,
    loader: nurseryLoader,
  },
];

const router = createBrowserRouter([
  ...protectedRoutes.map((route) => ({
    ...route,
    element: <RequireAuth component={route.element} />,
  })),
  // other routes here
  {
    path: '*',
    element: <NotFound />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
