import React, { useEffect, useState } from 'react';
import { Badge, Descriptions, Steps, Tag, Image, Row, Col } from 'antd';
import './TreeModal.css';
import {
  createFromIconfontCN,
  LeftOutlined,
  RightOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import Carousel from 'react-bootstrap/Carousel';
import { parseMetadata } from '../common/metadata';
import { getAddress } from '../api';

const contentStyle = {
  margin: 0,
  color: '#fff',
  lineHeight: '360px',
  textAlign: 'center',
  background: '#ddd',
  height: '80vh',
  objectFit: 'cover',
};

const coverStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#ddd',
  height: '100%',
};

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
    }
    aria-hidden='true'
    aria-disabled={currentSlide === 0 ? true : false}
    type='button'
  >
    jisodjifo
  </button>
);

const App = (props) => {
  const { tree } = props;
  const onChange = (currentSlide) => {
    // console.log(currentSlide);
    setIndex(currentSlide);
  };

  const [index, setIndex] = useState(0);
  const [isMouseHovering, setMouseHovring] = useState(false);
  const [treeAddress, setTreeAddress] = useState();
  const [imageAddress, setImageAddress] = useState([]);

  useEffect(() => {
    (async () => {
      if (!tree.위치) return;
      const [lat, lng] = tree.위치.trim().split(',');
      const address = await getAddress({ lat, lng });
      if (address?.formatted_address) setTreeAddress(address.formatted_address);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const imageAddress = tree?.메타데이터.reduce(async (list, data) => {
        let addressList = await list;
        const metadata = parseMetadata(data.exif);
        const latitude = metadata?.gps?.latitude;
        const longitude = metadata?.gps?.longitude;
        if (latitude && longitude) {
          const { formatted_address } = await getAddress({
            lat: latitude,
            lng: longitude,
          });
          addressList = [...addressList, formatted_address];
          return addressList;
        } else {
          addressList = [...addressList, undefined];
          return addressList;
        }
      }, []);
      setImageAddress(await imageAddress);
    })();
  }, []);

  const onClick = (value) => {
    setIndex(value);
  };
  const formatPrice = (value) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const [visible, setVisible] = useState(false);

  return (
    <Row>
      <Col sm={24} md={12}>
        <div style={{ display: 'none' }}>
          <Image.PreviewGroup
            preview={{
              visible,
              onVisibleChange: (vis) => setVisible(vis),
              current: index,
            }}
          >
            {tree?.메타데이터?.length > 0 &&
              tree.메타데이터.map((data, index) => (
                <Image key={`preview-${index}`} src={data.url} />
              ))}
          </Image.PreviewGroup>
        </div>
        {/* <Carousel
          afterChange={onChange}
          autoplay={false}
          prevArrow={<SlickArrowLeft />}
          nextArrow={<RightOutlined />}
        >
          {tree.촬영물 || tree?.촬영물?.length > 0 ? (
            tree.촬영물.map((img, index) => (
              <div key={index}>
                <div style={coverStyle}>
                  <Image
                    style={contentStyle}
                    src={img}
                    preview={{ visible: false }}
                    onClick={() => setVisible(true)}
                  />
                </div>
              </div>
            ))
          ) : (
            <div>
              <div style={coverStyle} />
            </div>
          )}
          
        </Carousel> */}
        <Carousel>
          {tree.메타데이터 || tree?.메타데이터?.length > 0 ? (
            tree.메타데이터.map((data, index) => {
              const metadata = parseMetadata(data.exif);
              const time = metadata?.dateTime?.display;
              return (
                <Carousel.Item key={index}>
                  <div style={coverStyle}>
                    <img
                      style={contentStyle}
                      src={data.url}
                      onClick={() => setVisible(true)}
                      alt='slid'
                    />
                  </div>
                  <Carousel.Caption
                    onMouseOver={() => setMouseHovring(true)}
                    onMouseOut={() => setMouseHovring(false)}
                    style={{
                      backgroundColor: isMouseHovering
                        ? 'rgba(0,0,0,0.5)'
                        : 'transparent',
                      position: 'absolute',
                      top: 0,
                      height: 80,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {isMouseHovering ? (
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        onClick={() => setMouseHovring(false)}
                      >
                        <h6>{`촬영일자: ${time ? time : '정보 없음'}`}</h6>
                        <h6>{`촬영위치: ${
                          imageAddress[index]
                            ? imageAddress[index]
                            : '정보 없음'
                        }`}</h6>
                      </div>
                    ) : (
                      <DownOutlined />
                    )}
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })
          ) : (
            <div>
              <div style={coverStyle} />
            </div>
          )}
        </Carousel>
      </Col>
      <Col sm={24} md={12}>
        <Descriptions
          title=''
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item label='수종'>{tree?.수종}</Descriptions.Item>
          <Descriptions.Item label='규격' span={2}>
            {tree.R && <Tag color='magenta'>R {tree.R}</Tag>}
            {tree.H && <Tag color='volcano'>H {tree.H}</Tag>}
            {tree.B && <Tag color='geekblue'>B {tree.B}</Tag>}
            {tree.W && <Tag color='purple'>W {tree.W}</Tag>}
            {tree.P && <Tag color='orange'>W {tree.P}</Tag>}
          </Descriptions.Item>
          <Descriptions.Item label='데이터 수집인'>
            {tree.데이터수집인?.map((name, index) => (
              <Tag color='green' key={index}>
                {name}
              </Tag>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label='수형'>{tree?.수형}</Descriptions.Item>
          <Descriptions.Item label='수목 등급'>
            {tree?.수목등급}
          </Descriptions.Item>
          <Descriptions.Item label='목대 형태'>
            {tree?.목대형태}
          </Descriptions.Item>
          <Descriptions.Item label='훈련'>
            {tree.훈련여부 === true
              ? `${tree.훈련 ? tree?.훈련 : ''} / ${
                  tree.연차 ? tree?.연차 : ''
                } 연차`
              : '해당사항없음'}
          </Descriptions.Item>
          <Descriptions.Item label='위치'>{`${treeAddress}`}</Descriptions.Item>
          <Descriptions.Item label='농가명' span={2}>
            {tree?.농가명}
          </Descriptions.Item>
          <Descriptions.Item label='농가 연락처'>
            {tree?.농가연락처}
          </Descriptions.Item>
          <Descriptions.Item label='필지 주소' span={2}>
            {tree?.필지주소}
          </Descriptions.Item>
          <Descriptions.Item label='기타 메모' span={3}>
            {tree?.기타메모}
          </Descriptions.Item>
          <Descriptions.Item label='수목 판매가 (원)'>
            {tree.수목판매가 ? formatPrice(tree?.수목판매가) : ''}
          </Descriptions.Item>
          <Descriptions.Item label='최근 방문일'>
            {new Date(tree?.최근방문일)?.toLocaleString().split(',')[0]}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};

export default App;
