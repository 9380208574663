import { Button, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const CustomDropdownRender = ({ menu, input, onClick }) => {
  return (
    <>
      {menu}
      {input && (
        <>
          <Divider style={{ margin: '8px 0' }} />
          <Button
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
            type='text'
            icon={<PlusOutlined />}
            onClick={onClick}
          >
            {`${input} (으)로 설정하기`}
          </Button>
        </>
      )}
    </>
  );
};

export default CustomDropdownRender;
