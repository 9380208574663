import React from 'react';
import { InstantSearch } from '../../common/algoliasearch';
import LandForm from '../../components/LandForm';
import { useLoaderData } from 'react-router-dom';

const LandFormWrapper = () => {
  return (
    <InstantSearch indexName='land_index'>
      <LandForm />;
    </InstantSearch>
  );
};

export default LandFormWrapper;
